import * as React from "react";
import { Box } from "@chakra-ui/react";
import Header from "../components/header/header";
import LandingPage from "../components/landing_page/landing_page";

const IndexPage = () => {
  return (
    <Box>
      <Header />
      <LandingPage />
    </Box>
  );
};

export default IndexPage;
