import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { OpaliteButtonLogin } from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import BackgroundAmorphusGradient from "../../images/background_amorphus_gradient.svg";
import BackgroundAmorphusGradientDesktop from "../../images/background_gradient_desktop.svg";
import { navigate } from "gatsby";
import { ApiContext } from "../Root";
import { TikTokController } from "../../services/tiktok";

interface LandingPageProps {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const api = useContext(ApiContext);
  const tikTokController = new TikTokController(api.client);

  useEffect(() => {
    tikTokController.createTikTokConnection();
  }, []);

  return (
    <Box
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      backgroundRepeat="no-repeat"
      backgroundImage={{
        base: BackgroundAmorphusGradient,
        md: BackgroundAmorphusGradientDesktop,
      }}
      backgroundSize="100%"
      backgroundPosition={{
        base: "center 50px",
        md: "bottom",
        lg: "0 2.5rem",
        xl: "0  5rem",
      }}
      height="100vh"
    >
      <Box
        padding="2rem"
        width={{ base: "80%", md: "40%" }}
        position="fixed"
        bottom=".25rem"
        left="0"
      >
        <OpaliteTextTitle marginBottom=".5rem">
          Mint your influence.
        </OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="1rem">
          We enable high-performing influencers like you to turn hundreds of
          posts into cash churning NFT's... all in the blink of an eye.
        </OpaliteTextStandard>
        <OpaliteButtonLogin
          onClick={() => tikTokController.setupTikTok()}
          marginTop="1rem"
        >
          Log into TikTok
        </OpaliteButtonLogin>
      </Box>
    </Box>
  );
};

export default LandingPage;
